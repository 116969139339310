/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'primeng/resources/themes/md-light-indigo/theme.css';
@import 'primeng/resources/primeng.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding-top: 51px;

  --avatar-size: 2rem;

  --text-color: black;
  --text-color-gray: rgba(0, 0, 0, 0.2509803922);
  --bkg-color: white;
  --bkg-hover: #e6e6e6;

  --primary: #0058FF;
  --primary-hover: #001EFF;
  --primary-disabled: #0058FF;

  --secondary: #E6E6E6;
  --secondary-hover:#CCCCCC ;
  --secondary-disabled: #E0E0E0;

  --success: #45BA77;
  --success-hover: #3EA76B;
  --success-disabled: #45BA77;

  --danger: #F24355;
  --danger-hover: #F0283D;
  --danger-disabled: #F24355;

  --warning: #FC5D3A;
  --warning-hover: #FB461D;
  --warning-disabled: #FC5D3A;
}

body.dark-theme {
  --text-color: white;
  --text-color-gray: white;
  --bkg-color: #16213e;
  --bkg-hover: #72727420;
}

body [class^='mat-'],
body [class*=' mat-'] {
  font-family: 'Aileron', Roboto, 'Helvetica Neue', sans-serif;
}
.material-icons {
  font-family: 'Material Icons' !important;
}
.red-snackbar > div {
  background: #f44336 !important;
  color: white;
}

.green-snackbar > div {
  background: #90b134 !important;
  color: white;
}

.qpp-btn {
    color: white !important;
    cursor: pointer;
    border-radius: 8px;
}
.qpp-btn.primary {
    background-color: var(--primary) !important;
}
.qpp-btn.primary:hover {
    background-color: var(--primary-hover) !important;
}
.qpp-btn.primary:disabled {
    background-color: var(--primary-disabled) !important;
}

.qpp-btn.warning {
    background-color: var(--warning) !important;
}
.qpp-btn.warning:hover {
    background-color: var(--warning-hover) !important;
}
.qpp-btn.warning:disabled {
    background-color: var(--warning-disabled) !important;
}